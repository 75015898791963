
import axios from 'axios'

 


export const GetMeals = (offset) => {

    return axios
    .get(`https://horeca-370718.ew.r.appspot.com/getmeals/${offset}`)
    .then((response) => {
        return response.data
    })

}
