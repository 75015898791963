import React from 'react'
import "../styles/footer.css"
const Footer = () => {
  return (
    <div className='footer'>
      <a className="link" href="https://wolfolthuis.com" target="_blank" rel="noopener noreferrer"> &#64; Wolf Olthuis</a>

    
    </div>
  )
}

export default Footer