

import axios from 'axios'

 


export const GetHighscore = (id) => {

    return axios
    .get(`https://horeca-370718.ew.r.appspot.com/highscore/${id}`)
    .then((response) => {
        return response.data
    })

}
